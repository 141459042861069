import { getMetadata, enumToString } from "@/utilities/helpers";
import messages_pb from "@preava/preava-prevent-api-grpc-web-js/messages_pb";
// import user_pb from "@preava/preava-prevent-api-grpc-web-js/user_pb"; // Warning: You don't need this. Use Public.
import ids_pb from "@preava/preava-prevent-api-grpc-web-js/ids_pb";
import public_pb from "@preava/preava-prevent-api-grpc-web-js/public_pb";
// import activate_pb from "@preava/preava-prevent-api-grpc-web-js/activate_pb"; // Warning: You don't need this. Use Messages/Empty.
import types_pb from "@preava/preava-prevent-api-grpc-web-js/types_pb";
import gateway_grpc_web_pb from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
// import { getApi } from "prv-js-common/endpoints";
import enums_pb from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { GRPC_ERROR } from "@/utilities/enums";
import { logout, getTokenSilently } from "@/utilities/auth";

/**
 * [DEV] Rules Only
 */
// import _rule_grpc_web_pb from "prv-api/pkg/web-js/rule_grpc_web_pb";
// import { OrgId, OrganizationItemB2B, OrganizationB2B, ObjectId } from "prv-api/pkg/web-js/ids_pb";

// import { RuleData, RulePayload } from "prv-api/pkg/web-js/rule_pb";

const getApi = (host) => {
  let modifiedHost = host.replace("admin", "api");
  return location.host.includes(":8080") ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
};

const ENUMS = enums_pb;
// const ENDPOINT = (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : getApi(location.host)
const ENDPOINT = getApi(location.host);

export function Adapter() {
  /**
   *
   * Debugging Variables.
   * @TODO: Make this global
   *
   */
  const NAME = "Adapter";
  const isLoggingAllowed = false;
  const log = isLoggingAllowed ? console.log.bind(console) : () => {};
  const error = isLoggingAllowed ? console.error.bind(console) : () => {};

  this.activate = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      log(`[${NAME}.js]: Activating user...`);

      const metadata = await getMetadata("activate", traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.AccountClient(ENDPOINT, null, null);

      gateway.activate(request, metadata, async (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while activating user.`);
          error(err);
          // If error happened during Activation, always logout and display the error page
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
              err_data.correlationId
            )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          let response = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, response);
          log(`[${NAME}.js]: `, { response });
          /**
           *
           * Depending on the Status returned, make sure to redirect the user
           * to the right Error Pages.
           */
          switch (response.status) {
            case ENUMS.Activation.ACTIVATION_FAILED_ORG_NOT_FOUND:
              // Returned when an organization is unknown
              // i.e. contact sales to purchase an account
              // --> call to action: Contact Sales
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "sales",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_FAILED_ORG_NOT_ACTIVE:
              // Returned when an organization is known but inactive
              // i.e. contact admin to activate the account
              // --> call to action: Get Support
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_FAILED_USER_NOT_INVITED:
              // Returned when an organization is known but the user is not invited
              // i.e. contact admin to invite you
              // --> call to action: Get Support
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_FAILED_USER_NOT_SEATED:
              // Returned when the organization and the user are known, but no license
              // has been assigned to the user.
              // i.e. contact admin to assign a seat for you
              // --> call to action: Get Support
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_FAILED_DEADLINE_TOO_SHORT:
              // Returned when the client sets a grpc-timeout header which is too short
              // The activation should be carried on with grpc-timeout=15s
              // --> call to action: Get Support
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_FAILED_PROVISIONING_CONSISTENCY_NOT_GUARANTEED:
              // Returned when activate service cannot guarantee that the provisioning
              // has been executed correctly within a grpc-timeout >= 15s
              // In this specific case, the client should retry to activate 3 times
              // informing the user.
              // The ideal grpc-timeout is set to 15s on the current servers.
              // i.e. our servers are overloaded, please retry later
              // --> call to action: Get Support
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_FAILED_AUTHORIZATION_CONSISTENCY_REESTABLISHED:
              // ✅ When a user activates the account, Activate service sets the Auth0 Role
              // according to the prv_enums_v2.Role found in the User DB.
              // Consistency between the User directory and Auth0 is guaranteed by our code.
              // Although our code is awersome and we have tests, might always happen that
              // the Role in the User directory does not match the Role in Auth0.
              // This might be caused by a user with admin access to the Auth0 tenant who
              // decides to remove a User role form Auth0. As a client:
              // - upon receiving a CONSISTENCY_REESTABILISHED message...
              // - ... force a token refresh on the Auth0 SPA library
              // - and continue the flow accoridngly
              // The extra quality-prone developer would:
              // - activate one more time expecting ACTIVATION_ACTIVATED...
              // - ... and panic with an un-recoverable error if CONSISTENCY_REESTABILISHED
              // is returned In fact, it might happen that our code is buggy and it is
              // repetitively failing in performing the authorization alignment between
              // Auth0 and the User DB.
              // 🚩 NOTE: this is not yet implemented!!! ETA end of July 2022
              // --> call to action: Get Support
              error(`[${NAME}.js]: Error occurred while activating user: ${enumToString(ENUMS.Activation, response.status)}`);
              error(`[${NAME}.js]: Message: ${response.bottle.message.value} , Details: ${response.bottle.details.value}`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;

            case ENUMS.Activation.ACTIVATION_ACTIVATED:
              // ✅ Returned upon successful activation
              // - all DBs/user-data provisioned
              // - all required API scopes/roles attached to the user
              // - the system is usable
              // - the system can be de-activated again if Dectivate() RPC is called
              // --> call to action: none, continue to application
              log(`[${NAME}.js]: Status Response Received. [${enumToString(ENUMS.Activation, response.status)}]`);
              log(`[${NAME}.js]: ${response.bottle.message.value}. ${response.bottle.details.value}`);
              resolve(response);
              break;

            case ENUMS.Activation.ACTIVATION_ACTIVATED_WITH_NEW_CREDENTIALS:
              // ✅ When a user activates the account for the first time, a new JWT token
              // needs to be requested.
              // --> call to action: none, continue to application
              log(`[${NAME}.js]: Status Response Received. Refreshing Token. [${enumToString(ENUMS.Activation, response.status)}]`);
              log(`[${NAME}.js]: ${response.bottle.message.value}. ${response.bottle.details.value}`);
              await getTokenSilently({ ignoreCache: true });
              resolve(response);
              break;

            default:
              error(`[${NAME}.js]: Unknown Status Received.`);
              err_data = {
                type: "support",
                code: response.status,
                status: `${enumToString(ENUMS.Activation, response.status)}`,
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;
          }
        }
      }); // end of gateway
    }); // end of promise
  }; // end of activate() method

  this.deactivate = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      /**
       * NOTE: Here, we don't reject the promise for UI to display error.
       * This is because we always logout the user every time
       * an issue is encountered during deactivation
       */

      log(`[${NAME}.js]: Deactivating user...`);

      const metadata = await getMetadata("deactivate", traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.AccountClient(ENDPOINT, null, null);

      gateway.deactivate(request, metadata, async (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while deactivating user.`);
          error(err);
          // If error happened during Deactivation, always logout and display the error page
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
              err_data.correlationId
            )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          let response = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, response);
          log(`[${NAME}.js]: `, { response });
          /**
           *
           * Depending on the Status returned, make sure to redirect the user
           * to the right Error Pages.
           */
          switch (response.status) {
            case ENUMS.Activation.ACTIVATION_DEACTIVATED:
              log(`[${NAME}.js]: Status Response Received.`);
              log(`[${NAME}.js]: ${response.bottle.message.value}. ${response.bottle.details.value}`);
              resolve(response);
              break;
            default:
              error(`[${NAME}.js]: Unknown Status Received.`);
              err_data = {
                type: "support",
                message: response.bottle.message.value,
                details: response.bottle.details.value,
                code: "N/A",
                status: "N/A",
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
              break;
          }
        }
      }); // end of gateway
    }); // end of promise
  }; // end of deactivate() method

  this.getIdentity = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId); // null=default request type
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.UserClient(ENDPOINT, null, null);

      log(`[${NAME}.js]: Retreiving identity of logged in user...`);
      gateway.getMyIdentity(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while retreiving identity`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          let user = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, res);
          log(`[${NAME}.js]: `, { user });
          resolve(user);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of getIdentity() method

  this.getEndpoints = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.UserClient(ENDPOINT, null, null);

      log(`[${NAME}.js]: Retreiving endpoints object...`);
      gateway.getEndpoints(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while retreiving endpoints object.`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          let endpoints = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, endpoints);
          log(`[${NAME}.js]: `, { endpoints });
          resolve(endpoints);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of getEndpoints() method

  this.getTenant = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      log(`[${NAME}.js]: Retreiving tenant object...`);
      gateway.getTenant(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while `);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          let tenant = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, tenant);
          log(`[${NAME}.js]: `, { tenant });
          resolve(tenant.account);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of getTenant() method

  this.getTenant_120 = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      log(`[${NAME}.js]: Retreiving tenant object...`);
      gateway.getTenant(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while `);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          let tenant = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, tenant);
          log(`[${NAME}.js]: `, { tenant });
          resolve(tenant);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of getTenant() method

  this.getSettings = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.UserClient(ENDPOINT, null, null);

      log(`[${NAME}.js]: Retreiving settings object...`);
      gateway.getSettings(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while retreiving settings object.`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          let settings = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, settings);
          log(`[${NAME}.js]: `, { settings });
          resolve(settings);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of getSettings() method

  this.setSettings = async function (traceId, _settings) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new public_pb.Settings();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      let footerContent = new types_pb.Text();
      footerContent.setValue(_settings.footerContent.value);

      request.setProtectionLevel(_settings.protectionLevel);
      request.setFooterStatus(_settings.footerStatus);
      request.setFooterContent(footerContent);
      request.setOfflineModeStatus(_settings.offlineModeStatus);
      request.setSendAnywaysStatus(_settings.sendAnywaysStatus);
      request.setSendAnywaysTimeoutMs(_settings.sendAnywaysTimeoutMs);

      request.setRecipientsEnabled(_settings.recipientsEnabled);
      request.setRecipientsProcessTo(_settings.recipientsProcessTo);
      request.setRecipientsProcessCc(_settings.recipientsProcessCc);
      request.setRecipientsProcessBcc(_settings.recipientsProcessBcc);
      request.setRecipientsEnableApproveAll(_settings.recipientsEnableApproveAll);
      request.setRecipientsEnableRememberThread(_settings.recipientsEnableRememberThread);

      request.setContentEnabled(_settings.contentEnabled);
      request.setContentProcessSubject(_settings.contentProcessSubject);
      request.setContentProcessBody(_settings.contentProcessBody);
      request.setContentProcessFilenames(_settings.contentProcessFilenames);
      request.setContentProcessAttachments(_settings.contentProcessAttachments);
      request.setContentEnableApproveAll(_settings.contentEnableApproveAll);
      request.setContentEnableRememberThread(_settings.contentEnableRememberThread);

      request.setTelemetryEnabled(_settings.telemetryEnabled);
      request.setTelemetryEnablePiiTagging(_settings.telemetryEnablePiiTagging);

      request.setTweaksIgnoreInlineImages(_settings.tweaksIgnoreInlineImages);
      request.setTweaksIgnoreMessageThread(_settings.tweaksIgnoreMessageThread);
      request.setTweaksIgnoreSignature(_settings.tweaksIgnoreSignature);

      request.setFilterTo(_settings.filterTo);
      request.setFilterCc(_settings.filterCc);
      request.setFilterBcc(_settings.filterBcc);
      request.setFilterSubject(_settings.filterSubject);
      request.setFilterBody(_settings.filterBody);
      request.setFilterFilenames(_settings.filterFilenames);
      request.setFilterAttachments(_settings.filterAttachments);

      log(`[${NAME}.js]: Updating settings object...`);
      gateway.setSettings(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while updating settings object.`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          let settings = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, settings);
          log(`[${NAME}.js]: `, { settings });
          resolve(settings);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of setSettings() method

  /**
   * WARNING: THIS CODE IS OUTDATED. PLEASE FIX.
   */
  this.listUsers = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      // const request = new user_pb.SearchUserReq();
      const request = new public_pb.SearchUserFilters();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);
      const stream = gateway.searchUsers(request, metadata);

      var _users = [];
      log(`[${NAME}.js]: The user stream is now starting.`);
      stream.on("data", function (res) {
        let _user = res.toObject().user;
        _user.mutables = res.toObject().mutablesList; // include the mutables array for processing on new User object later
        log(`[${NAME}.js]: A user stream response was received from the backend:`, res);
        log(`[${NAME}.js]: `, res.toObject());
        _users.push(_user);
      });

      stream.on("error", function (err) {
        let err_data = null;
        error(`[${NAME}.js]: A user stream error was returned:`, err);
        error(err);
        stream.cancel();
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout.
          // Otherwise, return the error to the UI for display
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
              err_data.correlationId
            )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          reject(err);
        }
      });

      stream.on("end", () => {
        log(`[${NAME}.js]: The user stream ended.`);
        stream.cancel();
        resolve(_users);
      });
    }); // end of promise
  }; // end of listUsers() method

  this.inviteUsers = async function (traceId, _emails) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new public_pb.InviteUsersRequest();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      // create an array of emails.
      // loop through emails sent, if user provided multiple emails
      let emails_array = [];
      _emails.forEach((_email) => {
        let email = new types_pb.Email();
        email.setValue(_email);
        emails_array.push(email);
      });

      // set the types_pb.EmailList() with the email array above
      let emails = new types_pb.EmailList();
      emails.setValuesList(emails_array);

      // Finally, set the request's emails
      request.setEmails(emails);

      log(`[${NAME}.js]: Sending invitations to provided emails:`, _emails);

      gateway.inviteUsers(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while sending email invitations.`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          log(`[${NAME}.js]: A response was received from the backend:`, res);
          let users = res.toObject();
          resolve(users.usersList);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of inviteUsers() method

  this.getLicense = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      log(`[${NAME}.js]: Retreiving license object...`);
      gateway.getLicense(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while retreiving license object.`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          // message LicenseResponse {
          //   uint64 seats_total = 1;
          //   uint64 seats_assigned = 2;
          //   uint64 seats_unassigned = 3;
          // }
          let license = res.toObject();
          log(`[${NAME}.js]: A response was received from the backend:`, license);
          log(`[${NAME}.js]: `, { license });
          resolve(license);
        }
      }); // end of gateway
    }); // end of promise
  }; // end of getLicense() method

  (this.searchTelemetry = async function (traceId) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);
      const request = new messages_pb.Empty();
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);
      const stream = gateway.searchTelemetry(request, metadata);

      var _telemetries = [];
      log(`[${NAME}.js]: The telemetry stream is now starting.`);
      stream.on("data", function (res) {
        let _telemetry = res.toObject().telemetry;
        log(_telemetry);
        // let _user = res.toObject().user
        // _user.mutables = res.toObject().mutablesList // include the mutables array for processing on new User object later
        log(`[${NAME}.js]: A telemetry stream response was received from the backend:`, res);
        log(`[${NAME}.js]: `, res.toObject());

        _telemetries.push(_telemetry);
      });

      stream.on("error", function (err) {
        let err_data = null;
        error(`[${NAME}.js]: A telemetry stream error was returned:`, err);
        stream.cancel();
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout.
          // Otherwise, return the error to the UI for display
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
              err_data.correlationId
            )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          reject(err);
        }
      });

      stream.on("end", () => {
        log(`[${NAME}.js]: The telemetry stream ended.`);
        stream.cancel();
        resolve(_telemetries);
      });
    }); // end of promise
  }), // end of searchTelemetry() method
    (this.getUser = async function (traceId, userId) {
      log(`[${NAME}.js]: Fetching user data for ${userId}...`);
      return new Promise(async (resolve, reject) => {
        const request = new ids_pb.UserId();
        request.setValue(userId);

        const metadata = await getMetadata(null, traceId);
        const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

        gateway.getUser(request, metadata, (err, res) => {
          let err_data = null;
          if (err) {
            error(`[${NAME}.js]: Error occurred while getting user ${userId}`);
            error(err);
            if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
              // If user is unauthenticated, always logout.
              // Otherwise, return the error to the UI for display
              err_data = {
                type: "support",
                message: err.message,
                details: err.details,
                code: err.code,
                status: enumToString(GRPC_ERROR, err.code),
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
            } else {
              reject(err);
            }
          } else {
            log(`[${NAME}.js]: A response was received from the backend:`, res);
            resolve(res.toObject());
          }
        });
      });
    }), // end of getUser() method
    (this.uninvite = async function (traceId, user) {
      log(`[${NAME}.js]: Uninviting ${user.email.value}...`);
      return new Promise(async (resolve, reject) => {
        const request = new ids_pb.UserId();
        request.setValue(user.userId.value);

        const metadata = await getMetadata(null, traceId);
        const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

        gateway.uninviteUser(request, metadata, (err, res) => {
          let err_data = null;
          if (err) {
            error(`[${NAME}.js]: Error occurred while uninviting user ${user.email.value}`);
            error(err);
            if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
              // If user is unauthenticated, always logout.
              // Otherwise, return the error to the UI for display
              err_data = {
                type: "support",
                message: err.message,
                details: err.details,
                code: err.code,
                status: enumToString(GRPC_ERROR, err.code),
                requestId: metadata["x-request-id"],
                correlationId: metadata["x-trace-id"],
              };
              logout({
                returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                  err_data.correlationId
                )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
              });
            } else {
              reject(err);
            }
          } else {
            log(`[${NAME}.js]: A response was received from the backend:`, res);
            log(`[${NAME}.js]: User ${user.email.value} is now uninvited.`);
            resolve(true);
          }
        });
      });
    }); // end of uninvite() method

  this.setSeat = async function (traceId, user, new_seat) {
    log(`[${NAME}.js]: Setting role for ${user.email.value} from ${user.seat.value} to ${new_seat}`);
    return new Promise(async (resolve, reject) => {
      const id = new ids_pb.UserId();
      id.setValue(user.userId.value);

      const request = new public_pb.SetSeatRequest();
      request.setUserId(id);
      request.setSeat(new_seat);

      const metadata = await getMetadata(null, traceId);
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      gateway.setSeat(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while setting seat for ${user.email.value} from ${user.seat.value} to ${new_seat}`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          log(`[${NAME}.js]: A response was received from the backend:`, res);
          // conditional here
          log(`[${NAME}.js]: Setting seat for ${user.email.value} from ${user.seat.value} to ${new_seat} was successful.`);
          resolve(true);
        }
      });
    });
  }; // end of setSeat() method

  this.setRole = async function (traceId, user, new_role) {
    log(`[${NAME}.js]: Setting role for ${user.email.value} from ${user.role.value} to ${new_role}`);
    return new Promise(async (resolve, reject) => {
      const id = new ids_pb.UserId();
      id.setValue(user.userId.value);

      const request = new public_pb.SetRoleRequest();
      request.setUserId(id);
      request.setRole(new_role);

      const metadata = await getMetadata(null, traceId);
      const gateway = new gateway_grpc_web_pb.AdminClient(ENDPOINT, null, null);

      gateway.setRole(request, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while setting role for ${user.email.value} from ${user.role.value} to ${new_role}`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          log(`[${NAME}.js]: A response was received from the backend:`, res);
          // conditional here
          log(`[${NAME}.js]: Setting role for ${user.email.value} from ${user.role.value} to ${new_role} was successful.`);
          resolve(true);
        }
      });
    });
  }; // end of SetRole() method

  this.getRules = async function (traceId, user) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId); // null=default request type

      const orgId = new OrgId();
      orgId.setValue(user.orgId.value);

      const request = new OrganizationB2B();
      request.setOrgId(orgId);

      const gateway = new _rule_grpc_web_pb.RuleClient(ENDPOINT, null, null);
      const stream = gateway.listRule(request, metadata);

      var _rules = [];
      log(`[${NAME}.js]: The rule client stream is now starting.`);
      stream.on("data", function (res) {
        let _rule = res.toObject();
        log(`[${NAME}.js]: A rule client stream response was received from the backend:`, res);
        log(`[${NAME}.js]: `, res.toObject());
        _rules.push(_rule);
      });

      stream.on("error", function (err) {
        let err_data = null;
        error(`[${NAME}.js]: A rule client stream error was returned:`, err);
        error(err);
        stream.cancel();
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout.
          // Otherwise, return the error to the UI for display
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
              err_data.correlationId
            )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          reject(err);
        }
      });

      stream.on("end", () => {
        log(`[${NAME}.js]: The rule client stream ended.`);
        stream.cancel();
        resolve(_rules);
      });
    }); // end of promise
  }; // end of getIdentity() method

  /**
   * EXPERIMENTAL: getRulesAsNonObject
   */
  this.getRulesAsNonObject = async function (traceId, user) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId); // null=default request type

      const orgId = new OrgId();
      orgId.setValue(user.orgId.value);

      const request = new OrganizationB2B();
      request.setOrgId(orgId);

      const gateway = new _rule_grpc_web_pb.RuleClient(ENDPOINT, null, null);
      const stream = gateway.listRule(request, metadata);

      var _rules = [];
      log(`[${NAME}.js]: The rule client stream is now starting.`);
      stream.on("data", function (res) {
        let _rule = res;
        log(`[${NAME}.js]: A rule client stream response was received from the backend:`, res);
        log(`[${NAME}.js]: `, res.toObject());
        _rules.push(_rule);
      });

      stream.on("error", function (err) {
        let err_data = null;
        error(`[${NAME}.js]: A rule client stream error was returned:`, err);
        error(err);
        stream.cancel();
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout.
          // Otherwise, return the error to the UI for display
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
              err_data.correlationId
            )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          reject(err);
        }
      });

      stream.on("end", () => {
        log(`[${NAME}.js]: The rule client stream ended.`);
        stream.cancel();
        resolve(_rules);
      });
    }); // end of promise
  }; // end of getIdentity() method

  /**
   * Update a rule here
   */
  this.updateRule = async function (traceId, user, rule, ruleNonObj) {
    return new Promise(async (resolve, reject) => {
      const metadata = await getMetadata(null, traceId);

      const rulePayload = new RulePayload();

      const orgId = new OrgId();
      const objectId = new ObjectId();
      const policyId = new ObjectId();
      const organizationItemB2B = new OrganizationItemB2B();

      orgId.setValue(user.orgId.value);
      objectId.setValue(rule.item.objectId.value);
      organizationItemB2B.setOrgId(orgId);
      organizationItemB2B.setObjectId(objectId);

      // Set the Rule Payload item
      rulePayload.setItem(organizationItemB2B);

      const ruleData = new RuleData();
      ruleData.setName(rule.data.name);
      ruleData.setPolicyId(policyId.setValue(rule.data.policyId.value));
      ruleData.setPriority(rule.data.priority);
      ruleData.setStatus(rule.data.status);
      ruleData.setLogging(rule.data.logging);

      /**
       * Set new Service Provision
       */
      let service_provision = new types_pb.Service();
      service_provision.setName(rule.data.serviceProvision.name);
      service_provision.setVersion(rule.data.serviceProvision.version);
      ruleData.setServiceProvision(service_provision);

      /**
       * Implementation starts here
       */

      // get all the implementation cases from the rule data protobuf as keys
      Object.keys(RuleData.ImplementationCase).forEach((implementation) => {
        // find the active implementation
        if (rule.data[implementation.toLowerCase()]) {
          let funcName = implementation.charAt(0).toUpperCase() + implementation.slice(1).toLowerCase(); // derive the name of the ruleData function from the implementation
          let theData = ruleNonObj.getData(); // get the data object
          let activeImplementation = theData[`get${funcName}`](); // get the active implementation from the data
          ruleData[`set${funcName}`](activeImplementation); // set this back as the ruleData implementation
        }
      });

      // Finally the Rule Payload data
      rulePayload.setData(ruleData);

      const gateway = new _rule_grpc_web_pb.RuleClient(ENDPOINT, null, null);

      gateway.updateRule(rulePayload, metadata, (err, res) => {
        let err_data = null;
        if (err) {
          error(`[${NAME}.js]: Error occurred while updating role`);
          error(err);
          if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
            // If user is unauthenticated, always logout.
            // Otherwise, return the error to the UI for display
            err_data = {
              type: "support",
              message: err.message,
              details: err.details,
              code: err.code,
              status: enumToString(GRPC_ERROR, err.code),
              requestId: metadata["x-request-id"],
              correlationId: metadata["x-trace-id"],
            };
            logout({
              returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(
                err_data.correlationId
              )}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}`,
            });
          } else {
            reject(err);
          }
        } else {
          log(`[${NAME}.js]: A response was received from the backend:`, res);
          // conditional here
          log(`[${NAME}.js]: Updating rule was successful.`);
          resolve(true);
        }
      });
    }); // end of promise
  }; // end of getIdentity() method
}
